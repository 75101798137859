import React from "react"

import SubTitle from "../../components/ui-kit/SubTitle"
import {columns, gap2, gap5, p2, rows} from "../../styles/styleUtils"
import {css, cx} from "@emotion/css"
import Button from "../../components/ui-kit/Button"
import Title from "../../components/ui-kit/Title"
import Icon from "../../components/ui-kit/Icon"
import {MdAttachMoney, MdOutlineBolt, MdPassword, MdPrivacyTip} from "react-icons/md"
import useStore from "../../store/useStore"

interface OnboardProps {
  onOpenSignInModal: () => {}
}

const iconStyles = css`width: 48px; height: 48px;color: rgb(234 88 11 / 60%);`

const textCenter = css`display: inline; text-align: center;`


const Landing = ({onOpenSignInModal}: OnboardProps) => {

  const breakpoint = useStore((s) => s.ui.activeBreakpoint)

  const featuresBorder = css`border-top: 1px solid var(--coolGrey3); border-bottom: 1px solid var(--coolGrey3);`
  let featuresStyles = cx(rows, css`justify-content: center;`, featuresBorder)

  if (breakpoint === 'tablet') {
    featuresStyles = cx(rows, css`justify-content: center; flex-wrap: wrap;`, featuresBorder)
  } else if (breakpoint === 'mobile') {
    featuresStyles = cx(columns, css`align-items: center;`, featuresBorder)
  }

  const featureBox = cx(columns, gap2, css`flex: 1; align-items: center; max-width: 320px;`, breakpoint === 'tablet' && css`min-width: 320px;`)
  
  return <div>
    <div className={cx(columns, gap5, css`padding: 80px 16px; align-items: center;`)}>
      <Title level="l1" className={css`display: inline; text-align: center;`}>
        Organize your online world
      </Title>
      <SubTitle level="l2" className={cx(p2, css`display: inline; text-align: center;`)}>
        The simplest bookmark manager, saving and organizing links has never been easier.
      </SubTitle>
      <div className={cx(rows, gap2, css`align-items: center; justify-content: center; flex-wrap: wrap;`)}>
        <span className={css`text-align: center;`}><Button size="large" onClick={onOpenSignInModal}>Sign-in</Button> to get started</span>
      </div>
    </div>
    <div className={cx(featuresStyles, css`gap: 40px;
        padding: 40px 24px;
        background: rgb(252 211 77 / 10%);`)}>
      <div className={cx(featureBox)}>
        <div><Icon glyph={MdOutlineBolt} className={iconStyles}/></div>
        <SubTitle level="l2" className={cx(textCenter)}>
          Lightning fast
        </SubTitle>
        <span className={cx(textCenter)}>Your data lives locally, remote synchronization happens in the background</span>
      </div>
      <div className={cx(featureBox)}>
        <div><Icon glyph={MdAttachMoney} className={iconStyles}/></div>
        <SubTitle level="l2" className={cx(textCenter)}>
          Free
        </SubTitle>
        <span className={cx(textCenter)}>No fees, for as long as we find free hosting to help us out</span>
      </div>
      <div className={cx(featureBox)}>
        <div><Icon glyph={MdPrivacyTip} className={iconStyles}/></div>
        <SubTitle level="l2" className={cx(textCenter)}>
          Privacy first
        </SubTitle>
        <span className={cx(textCenter)}>We value your privacy and don't share any of your data with third parties</span>
      </div>
      <div className={cx(featureBox)}>
        <div><Icon glyph={MdPassword} className={iconStyles}/></div>
        <SubTitle level="l2" className={cx(textCenter)}>
          No password
        </SubTitle>
        <span className={cx(textCenter)}>Hassle-free login, all you need is an email</span>
      </div>
    </div>
    <div className={cx(columns, gap5, css`padding: 80px 16px; align-items: center;`)}>
      <SubTitle level="l2" className={cx(p2, css`display: inline;
          text-align: center;`)}> Not sure? here a quick showcase of the product
      </SubTitle>
      <iframe
        className={cx(css`aspect-ratio: 16 / 9;`, breakpoint==='desktop' && css`width: 70%;`, breakpoint==='tablet' && css`width: 90%;`, breakpoint==='mobile' && css`width: 100%;`)}
        src="https://www.youtube.com/embed/5VkXJHUMKAw?si=WoRo7TDrbzCF9GbE"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      >
      </iframe>
    </div>
  </div>
}

export default Landing
