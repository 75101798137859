import React, {ChangeEvent, useEffect, useRef, useState} from "react"
import Button from "../../components/ui-kit/Button"
import {css, cx} from "@emotion/css"
import {gap2, rows} from "../../styles/styleUtils"
import {api} from "../../../src/App";
import fromChromeHtmlToJson from "../../utils/fromChromeHtmlToJson"
import chromeImport from "../../utils/chromeImport"
import {TransactionCreator} from "../../api/createEditingSession"
import useStore from "../../store/useStore"
import ImportTooLargeModal from "./ImportTooLargeModal"

const ImportButton = ({importing, setImporting}: { importing: boolean, setImporting: React.Dispatch<React.SetStateAction<boolean>> }) => {

  const filePickerRef = useRef<HTMLInputElement>(null)
  const [imported, setImported] = useState(false);
  const [limitExceeded, setLimitExceeded] = useState(false);

  useEffect(() => {
    let timeout: number
    if (imported) {
      timeout = window.setTimeout(() => {
        setImported(false)
      }, 4000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [imported])

  const onImport = (e: ChangeEvent<HTMLInputElement>) => {

    let file = e.target?.files?.[0];

    const reader = new FileReader();

    reader.addEventListener("load", async () => {
      const text = reader.result as string;
      setImporting(true)
      //splits long sync task:
      setTimeout(async () => {
        let transactionCreators: Array<TransactionCreator> = []
        try {
          const chromeExport = fromChromeHtmlToJson(text)
          transactionCreators = chromeImport(chromeExport, useStore.getState().entities)
        } catch (e) {
          console.error(e)
          alert(`An error occurred. Please check the selected file is a valid chrome export. Please get in touch if the problem persists.`)
          setImporting(false)
        }
        if (transactionCreators.length > 1200) {
          setLimitExceeded(true)
        } else {
          for (const transactionCreator of transactionCreators) {
            api.applyAndDispatch(transactionCreator)
          }
        }
        setImporting(false)
        setImported(true)
      }, 300)
    });

    file && reader.readAsText(file);

    if (filePickerRef.current) {
      filePickerRef.current.value = ""
    }

  }

  return <div className={cx(rows, gap2, css`align-items: center;`)}>
    {!importing && <Button
      size="large"
      variant='secondary'
      onClick={() => {
        filePickerRef.current?.click()
      }}
    >
      Select export .html
    </Button>}
    {importing && <>Import in progress...</>}
    {imported && <span className={css`color: var(--green6);`}>File imported.</span>}
    <input
      type="file"
      accept="text/html"
      ref={filePickerRef}
      style={{display: "none"}}
      onChange={onImport}
    />
    {limitExceeded && <ImportTooLargeModal onClose={() => setLimitExceeded(false)}/>}
  </div>

}

export default ImportButton
